import React from 'react';
import styled from '@emotion/styled';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const Root = styled('div')({
  backgroundColor: '#f5f5f5',
  padding: 16,
  '@media only screen and (max-width: 425px)': {
    padding: 0
  },
  '.content': {
    maxWidth: 700,
    margin: 'auto',
    backgroundColor: '#ffffff',
    padding: 16,
    boxShadow: '0 0 10px 0 rgba(0,0,0,0.3)'
  },
  ol: {
    paddingInlineStart: 24,
  },
  p: {
    textAlign: 'justify',
  }
});

class AppTermAndConditionPage extends React.Component {
  render() {
    return (
      <MuiThemeProvider theme={createMuiTheme()}>
        <Root>
          <div className={'content'}>
            <Typography variant={'h4'} component={'h1'} paragraph>
              Privacy Policy
            </Typography>
            <Typography paragraph>

              Muze Innovation built the Pumpkin app as a Free app. This SERVICE
              is provided by Muze Innovation at no cost and is intended for use
              as is.
            </Typography>
            <Typography paragraph>
              This page is used to inform website visitors regarding our
              policies with the collection, use, and disclosure of Personal
              Information if anyone decided to use our Service.
            </Typography>
            <Typography paragraph>
              If you choose to use our Service, then you agree to the collection
              and use of information in relation to this policy. The Personal
              Information that we collect is used for providing and improving
              the Service. We will not use or share your information with anyone
              except as described in this Privacy Policy.
            </Typography>
            <Typography paragraph>
              The terms used in this Privacy Policy have the same meanings as in
              our Terms and Conditions, which is accessible at Pumpkin unless
              otherwise defined in this Privacy Policy.
            </Typography>
            <Typography gutterBottom variant={'h6'}>
              Information Collection and Use
            </Typography>
            <Typography paragraph>
              For a better experience, while using our Service, we may require
              you to provide us with certain personally identifiable
              information. The information that we request is will be retained
              by us and used as described in this privacy policy.
            </Typography>
            <Typography paragraph>
              The app does use third party services that may collect information
              used to identify you.
            </Typography>
            <div>
              <Typography>
                Link to privacy policy of third party service providers used by
                the app
              </Typography>
              <ul>
                <li>
                  <Typography
                    component={'a'}
                    href="https://www.google.com/policies/privacy/"
                    target="_blank"
                    rel="noopener noreferrer">
                    Google Play Services
                  </Typography>
                </li>
              </ul>
            </div>
            <Typography variant={'h6'} gutterBottom>
              <strong>Log Data</strong>
            </Typography>
            <Typography paragraph>
              We want to inform you that whenever you use our Service, in a case
              of an error in the app we collect data and information (through
              third party products) on your phone called Log Data. This Log Data
              may include information such as your device Internet Protocol
              (“IP”) address, device name, operating system version, the
              configuration of the app when utilizing our Service, the time and
              date of your use of the Service, and other statistics.
            </Typography>
            <Typography variant={'h6'} gutterBottom>
              <strong>Cookies</strong>
            </Typography>
            <Typography paragraph>
              Cookies are files with a small amount of data that are commonly
              used as anonymous unique identifiers. These are sent to your
              browser from the websites that you visit and are stored on your
              device's internal memory.
            </Typography>
            <Typography paragraph>
              This Service does not use these “cookies” explicitly. However, the
              app may use third party code and libraries that use “cookies” to
              collect information and improve their services. You have the
              option to either accept or refuse these cookies and know when a
              cookie is being sent to your device. If you choose to refuse our
              cookies, you may not be able to use some portions of this Service.
            </Typography>
            <Typography variant={'h6'} gutterBottom>
              <strong>Service Providers</strong>
            </Typography>
            <Typography paragraph>

              We may employ third-party companies and individuals due to the
              following reasons:
            </Typography>
            <ul>
              <li>
                <Typography>To facilitate our Service;</Typography>
              </li>
              <li>
                <Typography>To provide the Service on our behalf;</Typography>
              </li>
              <li>
                <Typography>To perform Service-related services; or</Typography>
              </li>
              <li>
                <Typography>
                  To assist us in analyzing how our Service is used.
                </Typography>
              </li>
            </ul>
            <Typography paragraph>

              We want to inform users of this Service that these third parties
              have access to your Personal Information. The reason is to perform
              the tasks assigned to them on our behalf. However, they are
              obligated not to disclose or use the information for any other
              purpose.
            </Typography>
            <Typography variant={'h6'} gutterBottom>
              <strong>Security</strong>
            </Typography>
            <Typography paragraph>

              We value your trust in providing us your Personal Information,
              thus we are striving to use commercially acceptable means of
              protecting it. But remember that no method of transmission over
              the internet, or method of electronic storage is 100% secure and
              reliable, and we cannot guarantee its absolute security.
            </Typography>
            <Typography variant={'h6'} gutterBottom>
              <strong>Links to Other Sites</strong>
            </Typography>
            <Typography paragraph>
              This Service may contain links to other sites. If you click on a
              third-party link, you will be directed to that site. Note that
              these external sites are not operated by us. Therefore, we
              strongly advise you to review the Privacy Policy of these
              websites. We have no control over and assume no responsibility for
              the content, privacy policies, or practices of any third-party
              sites or services.
            </Typography>
            <Typography variant={'h6'} gutterBottom>
              <strong>Children’s Privacy</strong>
            </Typography>
            <Typography paragraph>
              These Services do not address anyone under the age of 13. We do
              not knowingly collect personally identifiable information from
              children under 13. In the case we discover that a child under 13
              has provided us with personal information, we immediately delete
              this from our servers. If you are a parent or guardian and you are
              aware that your child has provided us with personal information,
              please contact us so that we will be able to do necessary actions.
            </Typography>
            <Typography variant={'h6'} gutterBottom>
              <strong>Changes to This Privacy Policy</strong>
            </Typography>
            <Typography paragraph>

              We may update our Privacy Policy from time to time. Thus, you are
              advised to review this page periodically for any changes. We will
              notify you of any changes by posting the new Privacy Policy on
              this page. These changes are effective immediately after they are
              posted on this page.
            </Typography>
            <Typography variant={'h6'} gutterBottom>
              <strong>Contact Us</strong>
            </Typography>
            <Typography paragraph>
              If you have any questions or suggestions about our Privacy Policy,
              do not hesitate to contact us.
            </Typography>
          </div>
        </Root>
      </MuiThemeProvider>
    );
  }
}

export default AppTermAndConditionPage;
